<template>
  <div class="press">
    <div class="wide container">
      <div class="title-press">
        <div class="title-folder-top">Press</div>
        <div class="title-folder-center">The press writes about us</div>
      </div>
      <div class="action-slide hide-on-mobile-tablet">
        <div class="arrow arrow-left" @click="handleClickArrow('prev')">
          <span class="left"></span>
        </div>
        <div class="arrow arrow-right" @click="handleClickArrow('next')">
          <span class="right"></span>
        </div>
      </div>
    </div>
    <div class="wrap-slick">
      <VueSlickCarousel v-bind="settings" ref="carousel">
        <div>
          <div class="slide-item po-relative">
            <img src="../assets/images/img-press1.jpg" alt="" />
            <div class="slide-item-title">Beedu changes the way companies work, not just solve problems</div>
            <div class="information">
              <div class="newspaper">
                <img src="../assets/images/logozing.svg" alt="" />
              </div>
              <div class="time">17/01/2022</div>
            </div>
          </div>
        </div>
        <div>
          <div class="slide-item po-relative">
            <img src="../assets/images/img-press2.jpg" alt="" />
            <div class="slide-item-title">Beedu pioneers in applying blockchain tech to resort tourism</div>
            <div class="information">
              <div class="newspaper">
                <img src="../assets/images/iconcoindesk.svg" alt="" />
              </div>
              <div class="time">17/01/2022</div>
            </div>
          </div>
        </div>
        <div>
          <div class="slide-item po-relative">
            <img src="../assets/images/img-press3.jpg" alt="" />
            <div class="slide-item-title">Beedu changes the way companies work, not just solve problems</div>
            <div class="information">
              <div class="newspaper">
                <img src="../assets/images/logozing.svg" alt="" />
              </div>
              <div class="time">17/01/2022</div>
            </div>
          </div>
        </div>
        <div>
          <div class="slide-item po-relative">
            <img src="../assets/images/img-press1.jpg" alt="" />
            <div class="slide-item-title">Beedu changes the way companies work, not just solve problems</div>
            <div class="information">
              <div class="newspaper">
                <img src="../assets/images/logozing.svg" alt="" />
              </div>
              <div class="time">17/01/2022</div>
            </div>
          </div>
        </div>

        ></VueSlickCarousel
      >
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        // image1: image1,
        // image2: image2,
        settings: {
          dotsClass: 'slick-dots custom-dot-class',
          speed: 400,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          prevArrow: true,
          focusOnSelect: true,
          infinite: true,
          responsive: [
            {
              breakpoint: 739,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '50px'
              }
            },
            {
              breakpoint: 568,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '20px'
              }
            }
          ]
        }
      }
    },
    methods: {
      handleClickArrow(type) {
        if (type === 'next') {
          this.$refs.carousel.next()
        } else {
          this.$refs.carousel.prev()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .press {
    padding-top: 100px;

    .title-press {
      margin-bottom: 48px;
      .title-folder-top {
        font-size: 1.6rem;
        line-height: 19px;
        font-weight: 400;
        color: #999999;
      }
      .title-folder-center {
        font-size: 5.6rem;
        line-height: 6.7rem;
        font-weight: 600;
        color: #363636;
        margin-top: 5px;
      }
    }
    .information {
      display: inline-flex;
      margin-top: 16px;

      .newspaper {
        position: relative;
        margin-right: 20px;
        height: 20px;
      }
      .newspaper::before {
        content: '';
        position: absolute;
        top: 8px;
        right: -12px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #c4c4c4;
      }
      .time {
        color: #999999;
        font-size: 1.6rem;
        line-height: 19px;
        font-weight: 400;
      }
    }

    .wide {
      display: flex;
      justify-content: space-between;

      .action-slide {
        display: flex;
        margin-top: auto;
        margin-bottom: 48px;

        .arrow {
          display: inline-block;
          border-radius: 50%;
          cursor: pointer;

          .left,
          .right {
            display: inline-block;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: #fff;
            background: #f0f0f0;
          }

          .left {
            margin-right: 8px;
          }

          .left::after,
          .right::after {
            content: '';
            display: inline-block;
            margin-top: 18px;

            width: 11px;
            height: 11px;
            border-top: 3px solid #5a6273;
            border-right: 3px solid #5a6273;
          }

          .left::after {
            margin-left: 20px;
            -moz-transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
          }

          .right::after {
            margin-left: 15px;
            -moz-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }

    .wrap-slick {
      max-width: 1300px;
      margin: 0 auto;
      .slide-item {
        margin: 0 16px;
        overflow: hidden;

        img {
          object-fit: cover;
        }
        &-title {
          font-size: 2.4rem;
          line-height: 36px;
          font-weight: 600;
          margin-top: 24px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          display: -webkit-box;
          color: #363636;
        }
        .title-image {
          bottom: 40px;
          left: 40px;

          .title-image-1 {
            font-size: 36px;
            color: #fff;
            margin-bottom: 12px;
          }

          .title-image-2 {
            font-size: 20px;
            color: #fff;
          }
        }

        .pagination {
          bottom: 40px;
          right: 40px;
          width: 76px;
          height: 41px;
          background: #ffffff;
          opacity: 0.7;
          border-radius: 40px;
          color: #31353e;
          font-size: 20px;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .slick-list {
        padding: 0 calc((100vw - 1392px) / 2) px;
      }
    }

    @media (min-width: 1025px) and (max-width: 1440px) {
      .wide {
        width: calc(100vw - 60px);
      }
    }
    // tablet & mobile
    @media (max-width: 1024px) {
      .hide-on-mobile-tablet {
        display: none !important;
      }
      .title-press {
        text-align: center;

        .title-folder-top {
          justify-content: center;
          color: #999999;
        }

        .title-folder-center {
          font-size: 32px;
          line-height: 48px;
        }

        .title-folder-bottom {
          line-height: 28px;
        }
      }
    }
    // tablet
    @media (min-width: 740px) and (max-width: 1024px) {
      padding-top: 80px;
      padding-bottom: 80px;
      .hide-on-tablet {
        display: none;
      }
      .slide-item {
        .title-image {
          bottom: 16px !important;
          left: 16px !important;

          .title-image-1 {
            width: 240px !important;
            font-size: 20px !important;
          }

          .title-image-2 {
            font-size: 16px !important;
          }
        }

        .pagination {
          bottom: 16px !important;
          right: 16px !important;
          width: 40px !important;
          height: 20px !important;
          font-size: 13px !important;
        }
      }
    }
    // mobile
    @media (max-width: 739px) {
      padding-top: 56px;
      .hide-on-mobile {
        display: none;
      }
      .wrap-slick {
        .slide-item-title {
          font-size: 1.8rem;
          line-height: 2.7rem;
        }
      }
      .wide {
        justify-content: center;
        padding: 0 20px;
      }
      .title-press {
        text-align: center;
        margin-bottom: 5.6rem;

        .title-folder-top {
          justify-content: unset;

          span {
            font-size: 14px;
            line-height: 24px;
          }
        }

        .title-folder-center {
          font-size: 3.6rem;
          line-height: 4.3rem;
        }

        .title-folder-bottom {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .slide-item {
        margin: 0 8px !important;

        .title-image {
          bottom: 16px !important;
          left: 16px !important;

          .title-image-1 {
            font-size: 16px !important;
          }

          .title-image-2 {
            font-size: 12px !important;
          }
        }
      }
    }
  }
</style>

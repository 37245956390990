<template>
  <div class="story">
    <div class="story-content">
      “We work hard to build a company where all employees are valued, recognized, and able to succeed. It is important to us that our culture is one where diversity is celebrated
      and folks of all backgrounds feel they belong.”
    </div>
    <div class="story-author">Tony Hua, Founder & CEO</div>
    <div class="story-read">Read Beedu story</div>
    <img src="../assets/images/icon-story.svg" style="cursor: pointer" class="icon-click-detail-story" alt="" />
    <div class="popup-story">
      <img src="../assets/images/icon-close.svg" class="icon-close-me" alt="" />
      <div class="popup-story-header">Our Story</div>
      <div class="popup-story-content">
        “We work hard to build a company where all employees are valued, recognized, and able to succeed. It is important to us that our culture is one where diversity is
        celebrated and folks of all backgrounds feel they belong.”
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    mounted() {
      let popupstory = document.querySelector('.popup-story')
      let clickDetailStory = document.querySelector('.icon-click-detail-story')
      let iconCloseStory = document.querySelector('.icon-close-me')
      var bodyClose = document.querySelector('body')
      clickDetailStory.addEventListener('click', () => {
        popupstory.style.transform = 'translateY(0)'
        bodyClose.style.overflow = 'hidden'
      })
      iconCloseStory.addEventListener('click', () => {
        popupstory.style.transform = 'translateY(100%)'
        bodyClose.style.overflow = 'auto'
      })
    }
  }
</script>

<style lang="scss" scoped>
  .story {
    background: linear-gradient(90deg, #f23061 -7.43%, #faa227 100%);
    // padding: 6.4rem 11rem 0;
    padding: 110px 0 113px 0;
    color: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-content {
      max-width: 1366px;
      padding: 0 20px;
      font-size: 3.6rem;
      font-weight: 400;
      line-height: 5.4rem;
      text-align: center;
    }
    &-author {
      margin-top: 5.6rem;
      font-size: 2.4rem;
      line-height: 3.6rem;
      font-weight: 400;
    }
    &-read {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      margin-top: 3.2rem;
    }
    img {
      margin-top: 11px;
    }
    .popup-story {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: #ffffff;
      z-index: 4000;
      overflow: auto;
      padding: 80px 0;
      transform: translateY(100%);
      transition: all 0.5s linear;
      .popup-story-header {
        text-align: center;
        font-size: 50px;
        line-height: 44px;
        font-weight: 600;
        color: #1a1a1a;
      }
      .popup-story-content {
        font-size: 30px;
        text-align: center;
        font-weight: 400;
        font-style: italic;
        max-width: 1020px;
        margin: 0 auto;
        margin-top: 60px;
        display: grid;
        grid-gap: 30px;
        color: #1a1a1a;
      }
    }
    .icon-close-me {
      position: fixed;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }
  @media (max-width: 1100px) {
    .story {
      .popup-story {
        padding: 60px 0;
        .popup-story-header {
          text-align: center;
          font-size: 24px;
          line-height: 34px;
        }
        .popup-story-content {
          max-width: 1020px;
          padding: 0 20px;
          margin-top: 30px;
          font-size: 20px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .story-content {
      font-size: 2.4rem;
      line-height: 3.6rem;
    }
  }
  // @media (max-width: 739px) {
  //   .story {
  //     padding: 6.4rem 11rem 0;
  //   }
  // }
  @media (max-width: 739px) {
    .story {
      padding: 64px 0px 64px 0px;
      &-read {
        margin-top: 2.4rem;
      }
      img {
        margin-top: 8px;
      }
    }
  }
</style>

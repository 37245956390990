<template>
  <div class="title-reimagining">
    <div class="title-reimagining-header">Reimagining commerce for all.</div>
    <div class="title-reimagining-des">
      We’re working to find new and better ways to help businesses succeed, and we’re looking for people like you to help shape tomorrow at Beedu.
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
  .title-reimagining {
    padding: 110px 0 110px 0;
    max-width: 570px;
    margin: 0 auto;
    text-align: center;

    &-header {
      font-size: 5.6rem;
      line-height: 120%;
      font-weight: 600;
      color: #363636;
    }
    &-des {
      font-size: 2.4rem;
      line-height: 150%;
      font-weight: 400;
      color: #999999;
      margin-top: 3.2rem;
    }
  }
  @media (max-width: 739px) {
    .title-reimagining {
      padding: 64px 20px;
      max-width: 570px;
      margin: 0 auto;
      text-align: center;
      &-header {
        font-size: 3.6rem;
        line-height: 4.3rem;
      }
      &-des {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-top: 2.4rem;
      }
    }
  }
</style>

<template>
  <div>
    <div class="about-find">
      <img src="../assets/images/icon-find.svg" alt="" class="img-support" />
      <div class="about-find-title">Awards and achievements</div>
    </div>
    <div class="find-img">
      <img src="../assets/images/img-find1.svg" class="find-img-one" alt="" />
      <img src="../assets/images/img-find2.svg" alt="" />
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
  .about-find {
    color: #363636;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 11rem;
    .about-find-title {
      font-size: 5.6rem;
      font-weight: 600;
      line-height: 6.7rem;
      margin-top: 4.8rem;
      max-width: 758px;
    }
    &-des {
      font-size: 2.4rem;
      color: #999999;
      line-height: 3.6rem;
      font-weight: 400;
      margin-top: 4.8rem;
      max-width: 886px;
    }
  }
  .find-img {
    text-align: center;
    margin-top: 56px;
    margin-bottom: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    img {
      margin-top: 56px;
    }
    &-one {
      margin-right: 100px;
    }
  }

  @media (max-width: 1024px) {
    .about-find {
      .about-find-title {
        font-size: 3.6rem;
        line-height: 4.32rem;
        padding: 0px 20px;
      }
    }
    .find-img {
      margin-bottom: 64px;

      &-one {
        margin-right: 0px;
      }
    }
  }

  @media (max-width: 739px) {
    .about-find {
      padding-top: 6.4rem;
    }
    .find-img {
      margin-top: 5.8rem;
      margin-bottom: 119px;
      &-one {
        margin-right: 0;
        margin-bottom: 68px;
      }
      img {
        margin-top: 0px;
      }
    }
  }
</style>
